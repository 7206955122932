import { useCallback, useContext, useEffect, useState } from 'react';
// import logo from './logo.svg';
// import ReactDOM from "react-dom/client";
import {
  createBrowserRouter,
  RouterProvider,
  // Route,
  Outlet,
  NavLink,
  useParams,
  useNavigate,
  Navigate,
} from "react-router-dom";
import './App.css';

import debounce from 'lodash.debounce';
import { ApolloClient, InMemoryCache, ApolloProvider, gql, useQuery, ApolloLink, useMutation, HttpLink } from '@apollo/client';

import {
  FRAGMENT_AGEN_ALL_FIELDS,
  FRAGMENT_TBLOFFERS_ALL_FIELDS,
  FRAGMENT_TBLCONTACTS_ALL_FIELDS,

} from './Queries'
import { useLocalStorage } from './Hooks';


const TABLE_SIZE = 10
const DATE_REGEX = /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\+\d{2}:\d{2}/


const authLink = new ApolloLink((operation, forward) => {
  const token = localStorage.getItem('token');
  operation.setContext({
    headers: {
      authorization: token ? `Bearer ${JSON.parse(token)}` : '',
    }
  })
  return forward(operation)
})


const GRAPHQL_URI = (window.location.host === 'royalmanor-esimmo.masro.be')
  ? 'https://royalmanor-esimmo.masro.be/graphql'
  : window.location.host === 'pov-esimmo.masro.be'
  ? 'https://pov-esimmo.masro.be/graphql'
  : 'http://localhost:8000/graphql'
  ;


const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: ApolloLink.from([ authLink, new HttpLink({ uri: GRAPHQL_URI }) ])
});


const Navbar = () => {
  const activeTab = 'addr';
  const [storedToken, setStoredToken] = useLocalStorage('token')

  return (
    <nav class="navbar navbar-expand-lg navbar-dark bg-dark">
      <a class="navbar-brand" href="/">DATA VIEWER</a>
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarNav">
        <ul class="navbar-nav mr-auto">
          <li className={"nav-item"} >
            <NavLink className="nav-link" to="/contacts/">Carnet d'adresses</NavLink>
          </li>
          <li className={"nav-item"}>
            <NavLink className="nav-link" to="/offers/">Offres</NavLink>
          </li>
        </ul>
        <ul class="nav navbar-nav">
            <li class="nav-item">
                <a class="nav-link" href="#" onClick={() => {
                  setStoredToken(null)
                  window.location.href = "/";                  
                }}>Logout</a>
            </li>
        </ul>
      </div>
    </nav>
  )
}


const DebugRawData = ({ data }) => {
  const [show, setShow] = useState(false)
  return (
    <>
      <button onClick={() => setShow(!show)} className='btn btn-default'>{show ? 'hide' : 'show' } raw data</button>
      {show && (
        <pre style={{ marginTop: '1rem', padding: '1rem', background: 'black', color: 'lightgreen' }}>{JSON.stringify(data, null, 2)}</pre>
      )}
    </>
  )
}

const Loading = () => (
  <div class="spinner-border" role="status">
    <span class="sr-only">Loading...</span>
  </div>
)


const SearchOffers = () => {
  const skip = 0;
  const [ queryString, setQueryString ] = useState('');
  const navigate = useNavigate();

  const handleOnClick = useCallback(id => {
    navigate(`/offer/${id}`, { replace: false })
  }, [navigate])

  const { loading, error, data, refetch } = useQuery(gql`
    ${FRAGMENT_TBLOFFERS_ALL_FIELDS}
    query SearchOffersQuery ($q: SearchQueryInput!) {
      offers (q: $q) {
        total_count
        rows {
          offerid
          ...FragmentTblOffersFields
        }
      }
    }
  `, {
      variables: {
        q: {
          search: '',
          skip,
          first: TABLE_SIZE,
        }
      },
    },
  );

  const onSearch = async (queryString) => {
    await refetch({ q: {
      search: queryString,
      skip,
      first: TABLE_SIZE,
    }});
  }

  const deboucedOnSearch = useCallback(debounce(onSearch, 500), [])

  // if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;

  const searchResults = data?.offers?.rows || []

  return (
    <>
      <input class="form-control" autoFocus type="text" value={queryString} onChange={e => {
        setQueryString(e.target.value);
        deboucedOnSearch(e.target.value);
      }}/>
      <hr />

      {loading
        ? <p>Loading...</p>
        : (error)
        ? <p>Error :(</p>
        : (
          <>
            <h6>{data.offers?.total_count} results found</h6>
            <table className='table table-sm table-bordered'>
              <thead className='thead-dark'>
                <tr>
                  <th>Ref.</th>
                  <th>Type</th>
                  <th>Commune</th>
                  <th style={{textAlign: "right"}}>Prix</th>
                  <th>Classement</th>
                  <th>Contact</th>
                  <th>Created</th>
                </tr>
              </thead>
              <tbody>
                {searchResults.map((result, i) => (
                  <tr key={i} onClick={e => handleOnClick(result.offerid)} style={{ cursor: 'pointer' }}>
                    <td>{result.reference}</td>
                    <td>{result.type}</td>
                    <td>{result.locality}</td>
                    <td style={{textAlign: "right"}}>{result.price} €</td>
                    <td>{result.classifier}</td>
                    <td>{result.contact?.name1}</td>
                    <td>{result.created ? result.created.slice(0, 10) : ''}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </>
        )}
    </>
  );
}

const formatProfessions = list => list.map(item => item.profession.name.fr).join(', ')

const SearchContacts = () => {
  const [ skip, setSkip ] = useState(0);

  const [ queryString, setQueryString ] = useState('');
  const navigate = useNavigate()
  const handleOnClick = useCallback(id => {
    navigate(`/contact/${id}`, { replace: false });
  }, [navigate])

  const { loading, error, data, refetch } = useQuery(gql`
    query SearchAddressQuery ($q: SearchQueryInput!) {
      contacts(q: $q) {
        total_count
        rows {
          contactid
          code
          name1
          tblcontactsprofessions_set {
            profession {
              name {
                fr
              }
            }
          }
          phone
          email
        }
      }
    }
  `, {
    variables: {
      q: {
        search: '',
        skip,
        first: TABLE_SIZE,
      }
    }
  });

  const onSearch = async (queryString) => {
    await refetch({ q: {
      search: queryString, 
      skip,
      first: TABLE_SIZE,
    }});
  }

  const deboucedOnSearch = useCallback(debounce(onSearch, 500), [])

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;

  const searchResults = data?.contacts?.rows || []

  return (
    <>
      <input class="form-control" autoFocus type="text" value={queryString} onChange={e => {
        setQueryString(e.target.value);
        deboucedOnSearch(e.target.value);
      }}/>
      <br />
      {loading
        ? <p>Loading...</p>
        : (error)
        ? <p>Error :(</p>
        : (<>
          <h6>results found: {data?.contacts?.total_count}</h6>
          <table className='table table-sm table-bordered'>
            <thead className='thead-dark'>
              <tr>
                <th style={{ whiteSpace: 'nowrap' }}>Code</th>
                <th>Nom</th>
                <th>Type</th>
                <th>Phone</th>
                <th>Email</th>
              </tr>
            </thead>
            <tbody>
              {searchResults.map(r => (
                <tr style={{ cursor: 'pointer' }} onClick={() => handleOnClick(r.contactid)}>
                  <td>{r.code}</td>
                  <td>{r.name1}</td>
                  <td>{formatProfessions(r.tblcontactsprofessions_set)}</td>
                  <td>{r.phone}</td>
                  <td>{r.email}</td>
                </tr>
              ))}
            </tbody>
          </table>

          <div>
            <button className='btn btn-primary mr-2' disabled={skip <= 0} onClick={() => setSkip(skip - TABLE_SIZE)}>previous</button>
            <button className='btn btn-primary' onClick={() => setSkip(skip + TABLE_SIZE)}>next</button>
          </div>
      </>)}
    </>
  );
}


const GenericAgenListingComponent = ({ contactID, offerID, demandeID }) => {
  const count = 10
  const [ skip, setSkip ] = useState(0)
  const [ queryString, setQueryString ] = useState('')
  const { data, error, loading, refetch } = useQuery(gql`
    ${FRAGMENT_AGEN_ALL_FIELDS}
    query GenericAgenListing ( $q: String!, $skip: Int!, $count: Int!, $contactID: String, $offerID: String, $demandeID: String ) {
      agen_lookup ( q: $q, skip: $skip, count: $count, contactID: $contactID, offerID: $offerID, demandeID: $demandeID ) {
        count
        results {
          ...AgenAllFields
          offre {
            REFE
            IDEN
          }
          contact {
            REFE
            ContactID
          }
        }
      }
    }
  `, {
    variables: {
      q: queryString,
      skip: 0,
      count: 10,
      contactID,
      offerID,
      demandeID,
    }
  })
  const onSearch = queryString => {
    refetch({ q: queryString });
  }

  useEffect(() => {
    refetch({ skip })
  }, [skip])

  const deboucedOnSearch = useCallback(debounce(onSearch, 500), [])

  if (loading) return 'loading'
  if (error) return 'error'

  const nextIsDisabled = (data.agen_lookup.results.length === 0);
  const matchedCount = data.agen_lookup.count;

  return (
    <>
      <Header>AGENDA / TASKS ( found {matchedCount } )</Header>

      <input
        className='form-control'
        onChange={e => deboucedOnSearch(e.target.value)}
        placeholder="Search..."
        />

      <table className='table table-sm table-bordered' style={{ marginTop: '1rem' }}>
        <thead className='thead-dark'>
          <tr>
            <th></th>
            <th>Date</th>
            <th>Heure</th>
            <th style={{ whiteSpace: 'nowrap' }}>Lib</th>
            {/**
            <th style={{ whiteSpace: 'nowrap' }}>Rem 2</th>
             */}
            <th>Offr</th>
            <th>Contact</th>
          </tr>
        </thead>

        <tbody>
          {data.agen_lookup.results.map(agen => (
            <tr>
              <td>
                  <NavLink to={`/AGEN/${agen.taskID}`}>DETAILS</NavLink>
              </td>
              <td style={{ whiteSpace: 'nowrap' }}>{agen.DATE ? agen.DATE.substr(0, 10) : ''}</td>
              <td style={{ whiteSpace: 'nowrap' }}>{agen.HEUR_DEB} - {agen.HEUR_FIN}</td>
              <td>{agen.LIBE}</td>
              {/**
              <td>{agen.REMA2}</td>
              */}
              <td style={{ whiteSpace: 'nowrap' }}>
                {agen?.offre?.IDEN && (
                  <NavLink to={`/OFFR/${agen.offre.IDEN}`}>{agen.offre.REFE}</NavLink>
                )}
              </td>
              <td style={{ whiteSpace: 'nowrap' }}>
                {agen?.contact?.ContactID && (
                  <NavLink to={`/ADRE/${agen.contact.ContactID}`}>{agen.contact.REFE}</NavLink>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <button className='btn btn-sm btn-secondary'
        onClick={() => setSkip(Math.max(0, skip - count))}
        disabled={skip === 0}
        >
        prev
      </button>
      <button className='btn btn-sm btn-secondary'
        onClick={() => setSkip(skip + count)}
        disabled={nextIsDisabled}
        >
        next
      </button>

      <br /><br />
    </>
  )
}


const ContactsCommunicationsSet = ({ listing }) => (
  listing.length === 0
    ? <NoData title={"Communication"} />
    : <table  className='table table-sm table-bordered'>
        <thead className='thead-dark'>
          <tr>
            <th colSpan={2}>Communication</th>
          </tr>
        </thead>
        <tbody>
          {[...listing] // requires a copy here, for some reason. error raised by .sort() otherwiser
            .sort((a, b) => a.position - b.position)
            .map((item, i)  => (
            <tr key={i}>
              <td>{item.communication}</td>
              <td>{item.description}</td>
            </tr>
          ))}
        </tbody>
      </table>
)


const ListingWithCodeAndNameFr = ({ title, listing }) => {
  const mappedListingKey = ( listing.length === 0 )
    ? null
    : Object.keys(listing[0]).filter(k => k !== '__typename')[0]

  if (!mappedListingKey) return <NoData title={title} />

  const items = listing
    .map(i => i[mappedListingKey])
    .sort((a, b) => a.code - b.code)

  return (<>
    <table  className='table table-sm table-bordered'>
      <thead className='thead-dark'>
        <tr>
          <th>{title}</th>
        </tr>
      </thead>
      <tbody>
        {items.map((item, i)  => (
          <tr key={i}>
            {/** dont show the codes, they seem pointless here  */}
            <td>{item.name.fr}</td>
          </tr>
        ))}
      </tbody>
    </table>
  </>)
}


const ContactsCommonSet = ({ listing }) =>
  listing.length === 0
  ? <NoData title="Contacts common" />
  : <>
      {listing.map(((contactCommon, i) => (
        <>
          <KeyValueTable
            title={"Contacts common"}
            dict={contactCommon}
            ignoreKeys={['__typename', 'language', 'politesse', 'address']}
            />
          
          <KeyValueTable
            title="Contacts common > addr."
            dict={contactCommon.address || {}}
            ignoreKeys={['__typename', 'code']}
            ignoreValues={[null, ""]}
            />

          <table  className='table table-sm table-bordered'>
            <thead className='thead-dark'>
              <tr>
                <th colSpan={2}>Contacts common &gt; cont.</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Politesse</td>
                <td>{contactCommon?.politesse?.name?.fr || "No data"}</td>
              </tr>
              <tr>
                <td>Language</td>
                <td>{contactCommon?.language?.languagefr || "No data"}</td>
              </tr>
            </tbody>
          </table>
        </>
      )))}
    </>


const ContactOffersSet = ({ listing }) =>
  listing.length === 0
    ? <NoData title="Offers" />
    : <table  className='table table-sm table-bordered'>
        <thead className='thead-dark'>
          <tr>
            <th colSpan={2}>Offers</th>
          </tr>
        </thead>
        <tbody>
          {[...listing].sort((a, b) => a.created < b.created ? 1 : -1).map((offer, i) => 
            <tr key={i}>
              <td><NavLink to={`/offer/${offer.offerid}`}>{offer.reference}</NavLink></td>
              <td>{offer.created.slice(0, 10)}</td>
            </tr>
          )}
        </tbody>
      </table>


const Contact = () => {
  const { id } = useParams()
  const { data, loading, error } = useQuery(gql`
    ${FRAGMENT_TBLCONTACTS_ALL_FIELDS}
    query GetContactQuery ( $id: String! ) {
      contact ( id: $id ) {
        ...FragmentTblContactsFields
      }
    }
  `, {
    variables: {
      id
    }
  })
  if (loading) return 'loading'
  if (error) return 'error'
  const { contact } = data;
  return (
    <>
      <h2>{contact.reference1}</h2>
      <div className='row'>
        <div className='col-5'>
          <Header>DETAILS</Header>

          <KeyValueTable
            title="Details"
            dict={contact}
            ignoreKeys={[
              '__typename',
              'contactid',
              'tblrubrics_set',
              'tblcontactscommon_set',
              'tblcontactsprofessions_set',
              'tblcontactscommunications_set',
              'tbloffersintervenants_set',
              'tbloffers_set',
            ]}
            ignoreValues={[null, '', false]}
            timestamps={['created', 'modified']}
            />

          <ContactsCommonSet listing={contact.tblcontactscommon_set} />

          <ListingWithCodeAndNameFr
            title="Pro"
            listing={contact.tblcontactsprofessions_set}
            />

        </div>
        <div className='col-7'>
          <SearchRelatedTasks contactOrOfferId={contact.contactid} />
          <ContactsCommunicationsSet listing={contact.tblcontactscommunications_set} />
          <ContactOffersSet listing={contact.tbloffers_set} />
          <Intervenants listing={contact.tbloffersintervenants_set} />
          <DebugRawData data={contact} />
        </div>
      </div>
    </>
  )
}


const KeyValueTable = ({ title, ignoreValues, ignoreKeys, dict, timestamps }) => (
  <table className='table table-sm table-bordered'>
    <thead className='thead-dark'>
      <tr>
        <th colSpan={2}>{title}</th>
      </tr>
    </thead>
    <tbody>
      {(Object.keys(dict))
        .filter(key => (ignoreValues || []).indexOf(dict[key]) === -1)
        .filter(key => (ignoreKeys || []).indexOf(key) === -1)
        .map((key, i) => (
        <tr key={i}>
          <td>{key}</td>
          <td>{typeof dict[key] === 'object'
            // ? dict[key]?.name?.fr
            // : dict[key].name.fr
            ? '[DICT]'
            : Array.isArray(dict[key])
            ? '[LIST]'
            : (timestamps || []).indexOf(key) >= 0
            ? (dict[key] || "").slice(0, 10)
            : dict[key]} </td>
        </tr>
      ))}
    </tbody>
  </table>
)


const OffersBuilding = ({ item }) => (
  <KeyValueTable title="Building properties"
    dict={item}
    ignoreKeys={['__typename', 'offerid']}
    ignoreValues={[null, 0, '', '__typename', 'offerid']} />
)

const OffersBuildingsSet = ({ listing }) => (
  listing.length === 0
    ? <NoData title="Building details" />
    : <>
        {listing.map((item, i) => (
          <OffersBuilding key={i} item={item} />
        ))}
      </>
)


const OffersCommonSet = ({ listing }) => (
  listing.length === 0
    ? <NoData title="Offers common" />
    : <>
        {listing.map((item, i) =>
          <>
            <KeyValueTable
              key={i}
              title="Offers common"
              ignoreKeys={[
                "__typename",
                "availability",
                "description_short",
                "description_medium",
                "description_long",
                "mandate",
                "title_text",
              ]}
              ignoreValues={[null, 0, "", "0", "0.00", "0.0000"]}
              dict={item}
              />

            {item.title_text && (
              <h6 className="py-2"><strong>Title text:</strong> {item.title_text.fr}</h6>
            )}
            {item.availability && (
              <div className='py-2'><strong>Availability:</strong> {item.availability.name.fr}</div>
            )}
            {item.mandate && (
              <div className='py-2'><strong>Mandate:</strong> {item.mandate.name.fr}</div>
            )}
          </>
        )}
      </>
)


const Intervenants = ({ listing }) => (
  listing.length === 0
    ? <NoData title="Intervenants" />
    : <table className='table table-sm table-bordered'>
        <thead className='thead-dark'>
          <tr>
            <th colSpan={3}>Intervenants</th>
          </tr>
        </thead>
        <tbody>
          {listing.map((item, i) => (
            <tr key={i}>
              <td><NavLink to={`/contact/${item.contactid}`}>{item.contact.reference1}</NavLink></td>
              <td>{item.category?.name?.fr}</td>
              <td><NavLink to={`/offer/${item.offerid}`}>{item.offer.reference}</NavLink></td>
            </tr>
          ))}
        </tbody>
      </table>
)


const AgencySet = ({ listing }) => (
  listing.length === 0
    ? <NoData title="Agencies" />
    : <table className='table table-sm table-bordered'>
        <thead className='thead-dark'>
          <tr>
            <th colSpan={1}>Agencies</th>
          </tr>
        </thead>
        <tbody>
          {listing.map((item, i) => (
            <tr key={i}>
              <td>{item.contact.reference1}</td>
            </tr>
          ))}
        </tbody>
      </table>
)


const NoData = ({ title }) => (
  <div className='py-2'>
    <strong>{title}:</strong> No data.
  </div>
)


const Cadastres = ({ listing }) => (
  listing.length === 0
    ? <NoData title="Cadastres" />
    : <>
        {listing.map((cadastre, i) => (
          <KeyValueTable
            title={`Cadastre`}
            dict={cadastre}
            ignoreValues={['', 0, null]}
            ignoreKeys={['__typename']}
            />
        ))}
      </>
)


const OfferCommonDescriptions = ({ tblofferscommon_set }) => {
  return <>
    {tblofferscommon_set.map((offerscommon, i) => {
      const sm = offerscommon.description_short
      const md = offerscommon.description_medium
      const lg = offerscommon.description_long
      if (!(sm || md || lg)) return <NoData title="Offer description" />
      const items = [
        ['sm', sm],
        ['md', md],
        ['lg', lg],
      ]
      console.log('items', items)
      return (
        <table className='table table-sm table-bordered'>
          <thead className='thead-dark'>
            <tr>
              <th colSpan={2}>Description</th>
            </tr>
          </thead>
          <tbody>
            {items.filter(item => item[1] !== null).map((item, i) => (
              <tr key={i}>
                <td>{item[0]}</td>
                <td>{item[1].fr}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )
    })}
  </>
}


const SearchRelatedTasks = ({ contactOrOfferId }) => {
  const [ skip, setSkip ] = useState(0);
  const [ search, setSearch ] = useState('')
  const [ queryString, setQueryString ] = useState('');
  const navigate = useNavigate();
  const handleOnClick = useCallback(id => {
    navigate(`/offer/${id}`, { replace: false })
  }, [navigate])
  const { data, loading, error } = useQuery(gql`
    query TasksQuery ( $id: String!, $search: String!, $skip: Int!, $first: Int! ) {
      tasks ( id: $id, search: $search, skip: $skip, first: $first ) {
        total_count
        rows {
          name {
            fr
          }
          start
          stop
          contactid
          contact {
            name1
          }
          offerid
          offer {
            reference
          }
          creadate
        }
      }
    } 
  `, {
    variables: {
      id: contactOrOfferId,
      search,
      skip,
      first: TABLE_SIZE,
    }
  })

  if (error) return 'error...'
  return (
    <div className='p-2 mb-2' style={{ border: '1px solid black' }}>
      <input
        placeholder="Search tasks"
        className='form-control mb-2'
        value={search}
        onChange={e => setSearch(e.target.value)}
        />

      {data?.tasks && (
        <>
          <strong>Total results:</strong> {data.tasks.total_count}
        </>
      )}

      {loading
        ? <Loading />
        : data.tasks.total_count === 0
        ? <></>
        : <table className='table table-sm table-bordered'>
            <thead className='thead-dark'>
              <tr>
                <th>Created</th>
                <th>Contact</th>
                <th>Offer</th>
                <th>Debut</th>
                <th>Stop</th>
              </tr>
            </thead>
            <tbody>
              {data.tasks.rows.map((item, i) => (
                <tr key={i}>
                  <td>{item.creadate.slice(0, 10)}</td>
                  <td>{item.contact?.name1 ? <NavLink to={`/contact/${item.contactid}`}>{item.contact.name1}</NavLink> : ''}</td>
                  <td>{item.offer?.reference ? <NavLink to={`/offer/${item.offerid}`}>{item.offer.reference}</NavLink> : ''}</td>
                  <td>{(item.start || "").slice(0, 16).replace('T', ' ')}</td>
                  <td>{(item.stop || "").slice(0, 16).replace('T', ' ')}</td>
                </tr>
              ))}
            </tbody>
          </table>
      }

      <div className='mb-2'>
        <button className='btn btn-primary mr-2' onClick={() => setSkip(skip - TABLE_SIZE)} disabled={skip === 0}>previous</button>
        <button className='btn btn-primary'
          onClick={() => setSkip(skip + TABLE_SIZE)}
          disabled={data?.tasks?.total_count === 0 || data?.tasks?.rows?.length === 0 || (data?.tasks?.rows?.length || 0) < TABLE_SIZE}
          >next</button>
      </div>
    </div>
  )
}


const OfferDocuments = ({ documents }) => (
  <table className='table table-sm table-bordered'>
    <thead className='thead-dark'>
      <tr>
        <th>Path</th>
        <th>Description</th>
        <th>Modified</th>
      </tr>
    </thead>
    <tbody>
      {documents.map((item, i) => (
        <tr key={i}>
          <td>{item.docpath}</td>
          <td>{item.description}</td>
          <td>{(item?.modified || "").slice(0, 10)}</td>
        </tr>
      ))}
    </tbody>
  </table>
)


const Offer = () => {
  const { id } = useParams()
  const { data, loading, error } = useQuery(gql`
    ${FRAGMENT_TBLOFFERS_ALL_FIELDS}
    query GetOfferQuery ( $id: String! ) {
      offer ( id: $id ) {
        ...FragmentTblOffersFields
      }
      offer_documents ( id: $id ) {
        code
        docpath
        description
        modified
      }
    }
  `, {
    variables: {
      id
    }
  })
  if (loading) return 'loading'
  if (error) return 'error'
  const { offer } = data;

  console.log('offer = ', offer)

  return (
    <>
      <h2>{offer.reference}</h2>
      <Header>OFFER DETAILS</Header>
      <div className='row'>
        <div className='col-6'>
          {offer.contact
            ? <>Offer contact: <NavLink to={`/contact/${offer.contactid}`}>{offer.contact.reference1}</NavLink></>
            : <NoData title="Offer contact"/>
            }

          <div>Status: {offer.status.name.fr}</div>
          <div>Category: {offer.category.description}</div>

          <KeyValueTable
            title={'Offer Details'}
            dict={offer}
            ignoreKeys={[
              '__typename',
              'offerid',
              'agency_set',
              'status',
              'category',
              'tbloffersgeographics_set',
              'tbloffersbuildingtypes_set',
              'tbloffersbuildinginteriors_set',
              'tbloffersbuildingexteriors_set',
              'tbloffersbuildingstyles_set',
              'tbloffersintervenants_set',
              'tblofferscommon_set',
              'tbloffersbuildings_set',
              'tbloffersbuildingdestinations_set',
              'tblofferscadastres_set',
              'contact',
              'contactid',

              'tbloffersterrains_set', // ignoring for now... maybe to be implemented
            ]}
            ignoreValues={[null]}
            timestamps={['created', 'modified']}
            />

          <OfferCommonDescriptions tblofferscommon_set={offer.tblofferscommon_set} />

          <AgencySet listing={offer.agency_set} />

          <ListingWithCodeAndNameFr
            title="Geographics"
            listing={offer.tbloffersgeographics_set} />
          <ListingWithCodeAndNameFr
            title="Interior properties"
            listing={offer.tbloffersbuildinginteriors_set} />
          <ListingWithCodeAndNameFr
            title="Exterior properties"
            listing={offer.tbloffersbuildingexteriors_set} />

          <OffersCommonSet listing={offer.tblofferscommon_set} />

          <Intervenants listing={offer.tbloffersintervenants_set} />
          <Cadastres listing={offer.tblofferscadastres_set} />
        </div>
        <div className='col-6'>
          <OfferDocuments documents={data?.offer_documents || []} />
          <SearchRelatedTasks contactOrOfferId={offer.offerid} />

          <ListingWithCodeAndNameFr
              title="Building types"
              listing={offer.tbloffersbuildingtypes_set} />
          <ListingWithCodeAndNameFr
            title="Building style"
            listing={offer.tbloffersbuildingstyles_set} />
          <ListingWithCodeAndNameFr
            title="Building destination"
            listing={offer.tbloffersbuildingdestinations_set} />
          <OffersBuildingsSet listing={offer.tbloffersbuildings_set} />
          <DebugRawData data={offer} />
        </div>
      </div>
    </>
  )
}


const Header = ({ children }) => <h5>{children}</h5>


const LoginForm = ({ setStoredToken }) => {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [ TokenAuth ] = useMutation(gql`
    mutation TokenAuthMutation ( $username: String!, $password: String! ) {
      token_auth ( username: $username, password: $password ) {
        token
        payload
      }
    }
  `)

  const onLogin = async () => {
    const { data } = await TokenAuth({ variables: { username, password }})
    const { token_auth } = data
    const { token } = token_auth
    // localStorage.setItem('token', token)
    setStoredToken(token)
  }

  return (
    <div className='container'>
      <input placeholder='Username' className='form-control' onChange={e => setUsername(e.target.value)} />
      <input placeholder='Password' className='form-control' type='password' onChange={e => setPassword(e.target.value)} />
      <button className='btn btn-primary' onClick={onLogin}>login</button>
    </div>
  )
}


const Root = () => {
  const [storedToken, setStoredToken] = useLocalStorage('token', '')
  // const token = localStorage.getItem('token')
  const [verified, setVerified] = useState(false)
  const [ verifyToken ] = useMutation(gql`
    mutation VerifyTokenMutation ( $token: String! ) {
      verify_token ( token: $token ) {
        payload
      } 
    }
  `, {
    onCompleted: data => {
      setVerified(true);
    },
    onError: () => {
      setStoredToken('')
      setVerified(false)
    }
  })
  useEffect(() => {
    if (storedToken) {
      verifyToken({ variables: { token: storedToken }})
    } else {
      setVerified(false);
    }
  }, [storedToken, setVerified])

  // if (true) {
  if (verified) {
    return (
      <>
        <Navbar />
        <br />
        <div className="container-fluid">
          <Outlet />
        </div>
      </>
    )
  }

  return <LoginForm setStoredToken={setStoredToken} />
}

const router = createBrowserRouter([
  {
    path: '/',
    element: <Root />,
    children: [
      {
        path: 'offers/',
        element: <SearchOffers />
      },
      {
        path: 'contacts/',
        element: <SearchContacts />
      },
      {
        path: 'offer/:id',
        element: <Offer />,
      },
      {
        path: 'contact/:id',
        element: <Contact />,
      },
      // {
      //   path: 'AGEN/:id',
      //   element: <AgenComponent />
      // },
      {
        path: '*',
        element: <Navigate to="/offers/" replace />,
      },
      {
        element: <Navigate to="/offers/" replace />,
        index: true,
      },
    ],
  },
])

const App = () => {
  return (
    <ApolloProvider client={client}>
      <RouterProvider router={router} />
    </ApolloProvider>
  );
};

export default App;
